<template>
  <!-- <div class="text-center"> -->
  <!-- @click:outside="clickOutside" -->
  <v-dialog v-model="dialog" max-width="800" persistent>
    <template v-slot:activator="{ on, attrs }">
      <p
        id="fpbFormAdd"
        v-if="param.act === 'add'"
        v-bind="attrs"
        v-on="on"
        @click="add"
      ></p>
      <p id="fpbFormEdit" v-else v-bind="attrs" v-on="on" @click="update"></p>
    </template>
    <!-- <div v-if="detail !== null">
      <v-container style="max-width:100%;background: white; border-radius: 5px"> -->
    <v-form
      v-if="detail !== null"
      lazy-validation
      :readonly="detail.status.id !== 0"
      @submit.prevent="submit"
      ref="entryForm"
      style="position: relative"
      :disabled="
        getUserProfile.level.find(({ id }) => id === '41') !== undefined
      "
    >
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2"
          style="position: sticky; top: 0px; z-index: 2"
        >
          Edit FPB
        </v-card-title>
        <!-- <div>
              <v-btn
                type="submit"
                tile
                color="success"
                small
                class="ma-2"
                style="color: white"
                :loading="loading"
                :disabled="detail.status.id !== 0"
              >
                simpan
              </v-btn>

              <v-btn
                small
                tile
                color="pink"
                class="ma-2"
                style="color: white"
                @click="close"
                :loading="loading"
              >
                tutup
              </v-btn>
            </div> -->

        <v-card-text
          class="d-flex justify-center align-center"
          style="margin-top: 10px"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="5"
              style="
                padding: 0 0 0 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;
              "
            >
              <v-col
                cols="5"
                style="
                  padding: 0;
                  justify-content: flex-start;
                  align-items: center;
                  display: flex;
                "
              >
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Ada Budget ?
                </p>

                <v-checkbox
                  v-model="form.has_budget"
                  style="margin: 0 0 0 10px; height: 25px; padding: 0"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="7"
                style="
                  padding: 0;
                  justify-content: flex-start;
                  align-items: center;
                  display: flex;
                "
              >
                <v-tooltip v-if="param.act !== 'update'" bottom color="info">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      color="blue"
                      class="mr-1"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <span>Untuk mengaktifkan kolom ini, fpb harus project</span>
                </v-tooltip>
                <p
                  v-if="param.act !== 'update'"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Pilih dari katalog ?
                </p>
                <v-checkbox
                  v-if="param.act !== 'update'"
                  v-model="select_from_catalogue"
                  :false-value="false"
                  :true-value="true"
                  style="margin: 0 0 0 10px; height: 25px; padding: 0"
                  :disabled="param.act === 'add' && detail.project === null"
                ></v-checkbox>
              </v-col>
            </v-col>
            <v-col cols="12" md="2" style="padding: 0"></v-col>
            <v-col cols="12" md="5" style="padding: 0">
              <div
                v-if="param.act === 'add'"
                style="padding: 0 10px; margin-bottom: 20px"
              >
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    {{
                      !select_from_catalogue ? 'Nama Produk' : 'Item Katalog'
                    }}
                  </p>
                </v-col>
                <v-col
                  v-if="!select_from_catalogue"
                  cols="12"
                  style="padding: 0"
                >
                  <v-textarea
                    v-model="form.product.name"
                    dense
                    outlined
                    auto-grow
                    rows="1"
                    style="height: 40px"
                    :rules="itemNameRules"
                  />
                </v-col>
                <v-col v-else cols="12" style="padding: 0">
                  <v-autocomplete
                    v-model="selected"
                    :items="catalogueItems"
                    :filter="customFilter"
                    clearable
                    outlined
                    item-text="product.name"
                    item-value="id"
                    hide-details
                    hide-no-data
                    return-object
                    label=""
                    :loading="loading"
                    :search-input.sync="search"
                    style="margin: 0"
                    dense
                    @keydown.enter.prevent="enterSearch(search)"
                    :rules="itemNameRules"
                  >
                    <template v-slot:prepend-item>
                      <div
                        style="
                          padding: 0 15px;
                          position: sticky;
                          top: 0;
                          z-index: 2;
                        "
                      >
                        <div
                          style="
                            padding: 0 25px;
                            height: 25px;
                            background: black;
                            color: white;
                            border: 2px solid black;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                          "
                        >
                          <v-toolbar-title
                            style="
                              text-align: left;
                              font-size: 14px;
                              padding: 0;
                              width: 200px;
                            "
                          >
                            Item Name
                          </v-toolbar-title>
                          <v-divider class="mx-3" inset vertical></v-divider>
                          <v-toolbar-title
                            style="
                              text-align: left;
                              font-size: 14px;
                              padding: 0;
                              width: 120px;
                            "
                          >
                            Item Code
                          </v-toolbar-title>

                          <v-divider class="mx-3" inset vertical></v-divider>

                          <v-toolbar-title
                            style="
                              text-align: left;
                              font-size: 14px;
                              padding: 0;
                              width: 200px;
                            "
                          >
                            Supplier Name
                          </v-toolbar-title>

                          <v-divider class="mx-3" inset vertical></v-divider>

                          <v-toolbar-title
                            style="
                              text-align: left;
                              font-size: 14px;
                              padding: 0;
                              width: 120px;
                            "
                          >
                            Supplier code
                          </v-toolbar-title>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <!-- <div
                        style="width:800px; background:rgba(255,255,255,0.1);"
                      > -->
                      <!-- <v-expansion-panels
                        accordion
                        flat
                        light
                        style="width:800px;"
                      > -->
                      <!-- <v-expansion-panel> -->
                      <!-- <v-expansion-panel-header
                          hide-actions
                          style="border:1px solid rgba(0,0,0,0.1);"
                        > -->
                      <v-card
                        rounded
                        color="transparent"
                        style="
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          width: 800px;
                          padding: 7px;
                        "
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-toolbar-title
                              v-bind="attrs"
                              v-on="on"
                              style="
                                text-align: left;
                                font-size: 14px;
                                padding: 0;
                                width: 200px;
                              "
                            >
                              {{ item.product.name }}
                            </v-toolbar-title>
                          </template>
                          <span>{{ item.product.name }}</span>
                        </v-tooltip>
                        <v-divider class="mx-3" inset vertical></v-divider>
                        <v-toolbar-title
                          style="
                            text-align: left;
                            font-size: 14px;
                            padding: 0;
                            width: 120px;
                          "
                        >
                          #{{ item.product.code }}
                        </v-toolbar-title>

                        <v-divider class="mx-3" inset vertical></v-divider>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-toolbar-title
                              v-bind="attrs"
                              v-on="on"
                              style="
                                text-align: left;
                                font-size: 14px;
                                padding: 0;
                                width: 200px;
                              "
                            >
                              {{
                                item.vendor.name !== null
                                  ? item.vendor.name
                                  : ''
                              }}
                            </v-toolbar-title>
                          </template>
                          <span>{{
                            item.vendor.name !== null ? item.vendor.name : ''
                          }}</span>
                        </v-tooltip>

                        <v-divider class="mx-3" inset vertical></v-divider>

                        <v-toolbar-title
                          style="
                            text-align: left;
                            font-size: 14px;
                            padding: 0;
                            width: 120px;
                          "
                        >
                          #{{
                            item.vendor.code !== null ? item.vendor.code : ''
                          }}
                        </v-toolbar-title>
                      </v-card>
                      <!-- </v-expansion-panel-header> -->
                      <!-- </v-expansion-panel> -->
                      <!-- </v-expansion-panels> -->
                      <!-- </div> -->
                    </template>
                  </v-autocomplete>
                  <p
                    v-if="odooItem !== null"
                    :style="`font: 8px; color: ${
                      odooItem.status == '00' ? 'green' : 'red'
                    }`"
                  >
                    {{ '(' + odooItem.code + ') ' + odooItem.message }}
                  </p>
                </v-col>
              </div>
            </v-col>

            <!-- ---------------------IF act === UPDATE-------------------- -->
            <v-col
              v-if="param.act === 'update'"
              cols="12"
              md="5"
              style="padding: 0"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Item Name
                    <v-tooltip
                      v-if="form.product != null && form.product.id != null"
                      right
                      color="info"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="openDetailItem(form)"
                          style="cursor: pointer"
                          x-small
                          color="primary"
                        >
                          mdi-open-in-new
                        </v-icon>
                      </template>
                      <span>Lihat detail item</span>
                    </v-tooltip>
                  </p>
                </v-col>
                <v-col
                  v-if="form.product !== undefined"
                  cols="12"
                  style="padding: 0"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        :value="form.product.name"
                        dense
                        outlined
                      />
                    </template>
                    <span>{{ form.product.name }}</span>
                  </v-tooltip>
                </v-col>
              </div>
            </v-col>
            <v-col
              v-if="param.act === 'update'"
              cols="12"
              md="2"
              style="padding: 0"
            ></v-col>
            <v-col
              v-if="param.act === 'update'"
              cols="12"
              md="5"
              style="padding: 0"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Item Code
                    <!-- <v-tooltip right color="info">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="openDetailItem(form.product)"
                          :style="`{{form.product != null && form.product.id != null ? 'cursor:pointer;' : ''}}`"
                          x-small
                          color="primary"
                        >
                          mdi-open-in-new
                        </v-icon>
                      </template>
                      <span>Lihat detail item</span>
                    </v-tooltip> -->
                  </p>
                </v-col>
                <v-col
                  v-if="form.product !== undefined"
                  cols="12"
                  style="padding: 0"
                >
                  <v-text-field
                    readonly
                    :value="form.product.code"
                    dense
                    outlined
                  />
                </v-col>
              </div>
            </v-col>

            <v-col
              v-if="param.act === 'update'"
              cols="12"
              md="5"
              style="padding: 0"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Supplier Name
                  </p>
                </v-col>
                <v-col
                  v-if="form.vendor !== undefined"
                  cols="12"
                  style="padding: 0"
                >
                  <v-text-field
                    readonly
                    :value="form.vendor.name"
                    dense
                    outlined
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              v-if="param.act === 'update'"
              cols="12"
              md="2"
              style="padding: 0"
            ></v-col>
            <v-col
              v-if="param.act === 'update'"
              cols="12"
              md="5"
              style="padding: 0"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Supplier Code
                  </p>
                </v-col>
                <v-col
                  v-if="form.vendor !== undefined"
                  cols="12"
                  style="padding: 0"
                >
                  <v-text-field
                    readonly
                    :value="form.vendor.code"
                    dense
                    outlined
                  />
                </v-col>
              </div>
            </v-col>
            <!-- ---------------------------------------------------------------- -->

            <v-col cols="12" md="5" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Harga Penawaran
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    v-model="form.quotation_price_per_unit"
                    dense
                    outlined
                    :rules="quotationRules"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="2" style="padding: 0"></v-col>

            <v-col
              cols="12"
              md="5"
              style="padding: 0"
              v-if="param.act === 'update'"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Harga Terakhir
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    v-model="form.last_price"
                    dense
                    outlined
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="5" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Budget / Item
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    v-model="form.budget_price_per_unit"
                    dense
                    outlined
                    :rules="budgetRules"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="2"
              style="padding: 0"
              v-if="param.act === 'update'"
            ></v-col>
            <v-col cols="12" md="5" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="6" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Qty
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    v-model="form.qty"
                    type="number"
                    min="0.01"
                    dense
                    outlined
                    style="margin: 0"
                    :rules="qtyRules"
                  />
                </v-col>
              </div>
            </v-col>

            <v-col
              cols="12"
              md="2"
              style="padding: 0"
              v-if="param.act === 'add'"
            ></v-col>

            <v-col cols="12" md="5" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Total
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    :value="form.budget_price_total"
                    dense
                    outlined
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="2"
              style="padding: 0"
              v-if="param.act === 'update'"
            >
            </v-col>

            <v-col cols="12" md="5" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0"
                  ><p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Satuan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-autocomplete
                    v-if="param.act === 'add' && !select_from_catalogue"
                    v-model="form.uom"
                    :items="dropdownUnit"
                    clearable
                    outlined
                    item-text="name"
                    item-value="id"
                    hide-details
                    hide-no-data
                    return-object
                    style="margin: 0"
                    dense
                  />
                  <div v-else>
                    <!-- <v-autocomplete
                      v-model="form.uom"
                      :items="dropdownUnit"
                      clearable
                      outlined
                      item-text="name"
                      item-value="id"
                      hide-details
                      hide-no-data
                      return-object
                      style="margin: 0"
                      dense
                      disabled
                    /> -->
                    <div v-if="Object.keys(this.form).length > 0">
                      <div v-if="form.product !== null">
                        <v-autocomplete
                          v-if="form.product.code === null"
                          v-model="form.uom"
                          :items="dropdownUnit"
                          clearable
                          outlined
                          item-text="name"
                          item-value="id"
                          hide-details
                          hide-no-data
                          return-object
                          style="margin: 0"
                          dense
                        />
                        <v-autocomplete
                          v-else
                          v-model="form.uom"
                          :items="dropdownUnit"
                          clearable
                          outlined
                          item-text="name"
                          item-value="id"
                          hide-details
                          hide-no-data
                          return-object
                          style="margin: 0"
                          dense
                          disabled
                        />
                      </div>
                      <v-autocomplete
                        v-else
                        v-model="form.uom"
                        :items="dropdownUnit"
                        clearable
                        outlined
                        item-text="name"
                        item-value="id"
                        hide-details
                        hide-no-data
                        return-object
                        style="margin: 0"
                        dense
                        disabled
                      />
                    </div>
                  </div>
                </v-col>
              </div>
            </v-col>

            <v-col
              cols="12"
              md="5"
              style="padding: 0"
              v-if="param.act === 'update'"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Nomor PR
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field :value="form.pr_no" dense outlined readonly />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="2" style="padding: 0"> </v-col>
            <v-col cols="12" md="5" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="6" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Stock
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    v-model="form.current_stock"
                    type="number"
                    dense
                    outlined
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>

            <v-col
              cols="12"
              md="5"
              style="padding: 0"
              v-if="param.act === 'update'"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Status
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    :value="form.status !== undefined ? form.status.name : ''"
                    dense
                    outlined
                    readonly
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="2"
              style="padding: 0"
              v-if="param.act === 'update'"
            >
            </v-col>

            <v-col cols="12" md="5" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Tgl Diperlukan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0"
                  ><v-text-field
                    v-model="form.estimate_date_use"
                    outlined
                    dense
                    :label="form.estimate_date_use"
                    type="date"
                    name="date"
                    step="1"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="2"
              style="padding: 0"
              v-if="param.act === 'add'"
            ></v-col>
            <v-col cols="12" md="5" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0"
                  ><p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Upload File
                    <span style="font-size: 9px; color: orange">
                      <b>(cooming soon)</b>
                    </span>
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-file-input
                    disabled
                    prepend-icon=""
                    outlined
                    dense
                  ></v-file-input>
                </v-col>
              </div>
            </v-col>

            <v-col
              cols="12"
              md="2"
              style="padding: 0"
              v-if="param.act === 'update'"
            ></v-col>
            <v-col cols="12" md="5" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Estimasi data dilengkapi
                    <v-tooltip right color="info">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          x-small
                          color="blue"
                          class="mr-1"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        >Kolom ini diisi berdasarkan tanggal estimasi user harus
                        melengkapi data produk,</span
                      ><br />
                      <span
                        >sebagai timbal balik pengisian barang tanpa melalui
                        katalog</span
                      ><br />
                      <span
                        >Max rentang waktu estimasi adalah 1 bulan setelah
                        barang ini diinput disistem</span
                      >
                    </v-tooltip>
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    v-model="form.estimate_fulfillment"
                    outlined
                    dense
                    :label="form.estimate_fulfillment"
                    type="date"
                    name="date"
                    step="1"
                    :rules="estimateRules"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="2"
              style="padding: 0"
              v-if="param.act === 'add'"
            ></v-col>

            <v-col md="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Deskripsi
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-textarea
                    v-model="form.description"
                    dense
                    auto-grow
                    outlined
                  />
                </v-col>
              </div>
            </v-col>
            <v-col style="padding: 0" v-if="param.items !== undefined">
              <v-col style="padding: 0" v-if="param.items !== null">
                <v-col
                  v-if="param.items.status.id === 5"
                  md="12"
                  style="padding: 0"
                >
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Alasan Cancel
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-textarea
                        :value="param.items.cancel_reason"
                        dense
                        auto-grow
                        outlined
                        readonly
                        disabled
                      />
                    </v-col>
                  </div>
                </v-col>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="error" text outlined @click="close" :loading="loading">
            close
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            v-if="
              getUserProfile.level.find(({ id }) => id === '41') === undefined
            "
            color="primary"
            type="submit"
            text
            outlined
            :disabled="detail.status.id !== 0"
            :loading="loading"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
      <div>
        <DetailItem
          @showMsgDialog="showMsgDialog"
          :proc="proc"
          :selectedItem="selectedItem"
          :uploadAPI="uploadAPI"
        />
      </div>
    </v-form>
    <!-- </v-container>
    </div> -->
  </v-dialog>
  <!-- </div> -->
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  props: ['param', 'detail', 'wWidth', 'dropdownUnit'],
  components: {
    DetailItem: () => import('./DetailItemAndVendor.vue')
  },
  data() {
    return {
      proc: buildType.apiURL('proc'),
      uploadAPI: buildType.apiURL('upload2'),
      dialog: false,
      selected: null,
      catalogueItems: [],
      search: null,
      select: null,
      loading: false,
      form: {},
      select_from_catalogue: true,
      itemNameRules: [],
      detailRules: [],
      qtyRules: [],
      unitRules: [],
      estimateRules: [],
      budgetRules: [],
      quotationRules: [],
      odooItem: null,
      selectedItem: null
    }
  },
  watch: {
    select_from_catalogue() {
      if (!this.select_from_catalogue) {
        this.form.product = {
          id: null,
          name: null,
          code: null
        }
      } else {
        this.form.product = null
      }
    },
    search() {
      if (this.search === null || this.search === '') {
        this.catalogueItems = []
      }
    },
    selected() {
      console.log(this.selected)
      if (this.selected !== null) {
        if (this.selected.product.uom_id !== null) {
          this.form.uom = {
            id: this.selected.product.uom_id,
            name: this.selected.product.uom_name
          }
        }
        this.checkItemFromOdoo()
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUserProfile'
      // 'getDropdownProduct',
      // 'getDropdownVendor'
      // "getDropdownDocStatus",
    ])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    openDetailItem(obj) {
      console.log('>>openDetailItem', obj)
      this.selectedItem = obj
      setTimeout(() => {
        document.getElementById('fpbDetailItem').click()
      }, 500)
    },
    enterSearch(val) {
      val && val !== this.select && this.querySelections(val)
    },
    close() {
      this.select_from_catalogue = true
      setTimeout(() => {
        this.$emit('loadPage')
        this.dialog = false
        this.form = {}
        this.selected = null
        this.odooItem = null

        this.itemNameRules = []
        this.qtyRules = []
        this.unitRules = []
        this.detailRules = []
        this.estimateRules = []
        this.budgetRules = []
        this.quotationRules = []
      }, 200)
    },
    clickOutside() {
      this.$emit('loadPage')
      this.form = null
      this.dialog = {}
    },
    add() {
      this.form = {
        request_id: this.detail.id,
        product: null,
        qty: 0,
        budget_price_per_unit: 0,
        quotation_price_per_unit: 0,
        vendor: null,
        has_budget: 0,
        estimate_date_use: '',
        description: '',
        current_stock: 0,
        uom: null,
        estimate_fulfillment: null
      }
    },
    update() {
      setTimeout(() => {
        this.form = {
          request_id: this.detail.id,
          product: this.param.items.product,
          qty: this.param.items.qty,
          budget_price_per_unit: this.param.items.budget_price_per_unit,
          quotation_price_per_unit:
            this.param.items.quotation_price_per_unit !== undefined
              ? this.param.items.quotation_price_per_unit
              : 0,
          vendor: this.param.items.vendor,
          has_budget: this.param.items.has_budget,
          estimate_date_use: this.param.items.estimate_date_use,
          description:
            this.param.items.description === ''
              ? this.param.items.product.name
              : this.param.items.description,
          budget_price_total: this.param.items.budget_price_total,
          pr_no: this.param.items.pr_no,
          status: this.param.items.status,
          current_stock: this.param.items.current_stock || 0,
          uom: {
            id: this.param.items.uom_id,
            name: this.param.items.uom
          },
          cancel_reson: this.param.items.cancel_reson,
          estimate_fulfillment: this.param.items.estimate_fulfillment,
          vendor_catalogue_id: this.param.items.vendor_catalogue_id
        }
        console.log(this.form)
      }, 200)
    },
    submit() {
      const self = this
      this.itemNameRules = [(v) => !!v || 'Item is required']
      this.qtyRules = [
        (v) => !!v || 'QTY is required',
        (v) => v > 0 || 'QTY must be more than 0'
      ]
      this.unitRules = [(v) => !!v || 'Satuan is required']
      this.detailRules = [(v) => !!v || 'Deskripsi is required']
      this.budgetRules = [
        (v) => !/[^0-9]/.test(v) || 'Cannot use special character or alphabet '
      ]
      this.quotationRules = [
        (v) => !/[^0-9]/.test(v) || 'Cannot use special character or alphabet '
      ]
      if (this.detail.company.id != 5) {
        if (this.form.product !== null) {
          if (this.form.product.code === null) {
            this.estimateRules = [
              (v) => !!v || 'Estimasi is required',
              (v) => this.getDiffDays(v) <= 30 || 'Max 30 days'
            ]
          }
        }
      }
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm(self.form)
        }
      })
    },
    createNewForm(form) {
      this.loading = true

      try {
        const newForm = {
          request_id: form.request_id,
          qty: form.qty,
          budget_price_per_unit: form.budget_price_per_unit,
          quotation_price_per_unit: Number(form.quotation_price_per_unit),
          has_budget: form.has_budget ? 1 : 0,
          estimate_date_use: form.estimate_date_use,
          description: form.description,
          current_stock: form.current_stock,
          uom_id: form.uom !== null ? form.uom.id : null,
          uom_name: form.uom !== null ? form.uom.name : null,
          estimate_fulfillment: form.estimate_fulfillment,
          budget_plan_id: this.detail.budget_plan_id
        }

        if (this.param.act === 'update') {
          Object.assign(newForm, {
            act: this.param.act,
            id: this.param.items.id,
            product_id:
              form.product.id !== null ? Number(form.product.id) : null,
            product_name: form.product.name,
            product_code: form.product.code,
            vendor_id: form.vendor.id !== null ? Number(form.vendor.id) : null,
            vendor_name: form.vendor.name !== null ? form.vendor.name : null,
            vendor_code: form.vendor.code !== null ? form.vendor.code : null
          })
        } else {
          if (!this.select_from_catalogue) {
            Object.assign(newForm, {
              act: this.param.act,
              product_id: null,
              product_name: this.form.product.name,
              product_code: null,
              last_price: 0,
              vendor_id: null,
              vendor_name: null,
              vendor_code: null,
              is_item_match_with_odoo: 1
            })
          } else {
            Object.assign(newForm, {
              act: this.param.act,
              product_id: Number(this.selected.product.id),
              product_name: this.selected.product.name,
              product_code: this.selected.product.code,
              last_price: this.selected.product.last_price,
              vendor_id:
                this.selected.vendor.id !== null
                  ? Number(this.selected.vendor.id)
                  : '0',
              vendor_name:
                this.selected.vendor.name !== null
                  ? this.selected.vendor.name
                  : '0',
              vendor_code:
                this.selected.vendor.code !== ''
                  ? this.selected.vendor.code
                  : '0',
              is_item_match_with_odoo: this.odooItem != null ? this.odooItem.status == '00' ? 1 : 0 : 0
            })
          }
        }
        console.log(newForm)
        this.save(newForm)
        // this.loading = false
      } catch (error) {
        console.log(`error on form add item: ${error.message}`)
        this.loading = false
      }
    },
    save(form) {
      axios
        .post(`${this.proc}fpb/save_detail`, form)
        .then((res) => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            // if (this.param.act === 'add') {
            this.close()
            //   this.$emit('loadPage')
            // } else {
            //   this.$emit('loadPage')
            // }
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              let xFlag = false
              if (res.data.error_msg !== undefined) {
                if (res.data.error_msg !== null) {
                  if (res.data.error_msg.length > 0) {
                    this.showMsgDialog(
                      'error',
                      `${res.data.error_msg[0].msg} (${res.data.error_msg[0].value})`,
                      false
                    )
                  } else {
                    console.log('HERE 3')
                    xFlag = true
                  }
                } else {
                  console.log('HERE 2')
                  xFlag = true
                }
              } else {
                console.log('HERE 1')
                xFlag = true
              }

              if (xFlag) {
                this.showMsgDialog(
                  'error',
                  'Invalid input, Parameter value has problem',
                  false
                )
              }
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }

          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    querySelections(v) {
      const getCatalogue = () => {
        return new Promise((resolve) => {
          axios
            .get(
              // `${this.proc}vendor/catalogue/list?keyword=${v}&offset=0&limit=10&vendor_id=&category_id=`
              `${this.proc}vendor/catalogue/dropdown_product?keyword=${v}`
            )
            .then((res) => {
              resolve(res.data.data)
            })
        })
      }
      this.loading = true
      getCatalogue().then((res) => {
        if (res !== undefined) {
          this.catalogueItems = res
        } else {
          this.catalogueItems = []
        }
        this.loading = false
      })
    },

    customFilter(item, queryText, itemText) {
      if (item.vendor.name === null || item.vendor.code === null) {
        return (
          item.product.name
            .toLocaleLowerCase()
            .indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.product.code
            .toLocaleLowerCase()
            .indexOf(queryText.toLocaleLowerCase()) > -1
        )
      }
      return (
        item.product.name
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.product.code
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.vendor.name
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.vendor.code
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    checkItemFromOdoo() {
      console.log(this.selected)
      const itemArr = []

      const xItem = {
        code: this.selected.product.code,
        name: this.selected.product.name,
        uom: this.selected.product.uom_name,
        index: 0
      }

      itemArr.push(xItem)
      axios
        .post(`${this.proc}fpb/odoo/check_item`, { items: itemArr })
        .then((res) => {
          console.log(res)
          if (res.data.status_code === '00') {
            this.odooItem = res.data.data[0]
          }
          setTimeout(() => {
            this.loading = false
          }, 200)
        })
        .catch((err) => {
          this.loading = false
          this.showMsgDialog('error', `${err}`, false)
        })
    },
    getDiffDays(v) {
      console.log('estimate_date>>>>', v)
      const dateNow = new Date()
      const date2 = new Date(v)

      // Calculating the time difference
      // of two dates
      const Difference_In_Time = date2.getTime() - dateNow.getTime()

      // Calculating the no. of days between
      // two dates
      const Difference_In_Days = Math.round(
        Difference_In_Time / (1000 * 3600 * 24)
      )
      console.log('Difference_In_Days>>>>', Difference_In_Days)
      return Difference_In_Days
    }
  }
}
</script>
<style lang="scss" scoped>
@media (max-width: 808px) {
}
</style>
